import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import { PAGES, BASE_URL } from 'config';
import { FORMAT_GRAPH_DATA, getImageUrl } from 'utilities';
import { Layout } from 'containers';
import { ActivityList, OtherTypes } from 'layouts';
import { Banner, FooterBanner } from 'components';


const ActivityCategory = ({ pageContext, data }) => {
  const breadcrumbs = [
    {
      name: PAGES.ACTIVITIES.TITLE,
      absolute_slug: PAGES.ACTIVITIES.PATH,
    },
    {
      name: pageContext.name,
      absolute_slug: pageContext.absolute_slug,
    },
  ];

  const otherTypes = FORMAT_GRAPH_DATA(data.allActivityCategories)
    .filter(item => item.api_id !== pageContext.api_id);

  return (
    <Layout page="activities">
      <Helmet
        title={pageContext.page_title}
        meta={[
          { name: 'description', content: pageContext.meta_description },
          { name: 'og:title', content: pageContext.og_title },
          { name: 'og:description', content: pageContext.og_description },
        ]}
        link={[{ rel: 'canonical', href: `${BASE_URL}/activities/${pageContext.slug}/` }]}
      />

      <Banner
        backgroundImage={getImageUrl({
          id: pageContext.picture_id,
          images: data.allActivityImages.edges,
          height: 590,
        })}
      >
        <div className="c-heading c-heading--h0">
          {pageContext.name}
        </div>
      </Banner>

      <ActivityList
        title={pageContext.h1_header}
        subTitle={pageContext.description_markdown}
        list={pageContext.children}
        breadcrumbs={breadcrumbs}
      />

      <OtherTypes list={otherTypes} />

      <FooterBanner />
    </Layout>
  );
};

ActivityCategory.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allActivityCategories {
      edges {
        node {
          id
          api_id
          name
          absolute_slug
        }
      }
    }

    allActivityImages {
      edges {
        node {
          api_id
          imgix_url
        }
      }
    }
  }
`;

export default ActivityCategory;
